import { FormEvent, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useApiClient } from "src/api";

export default function ProlificLogin() {
  const apiClient = useApiClient();
  const location = useLocation() as {
    state?: { from: { pathname: string; search: string } };
  };
  const searchParams = useSearchParams()[0];
  const redirectPathname = location.state?.from?.pathname;
  const redirectSearch = location.state?.from?.search;
  const redirectUrl = redirectPathname
    ? `${redirectPathname}${redirectSearch}`
    : searchParams.get("next") || "/";
  const [prolificId, setProlificId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await apiClient
      .post("/prolific/login/", { prolific_id: prolificId })
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        if (error["non_field_errors"]) {
          setErrorMessage(error["non_field_errors"][0]);
        } else {
          setErrorMessage("Unable to login. Please try again!");
        }
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
            Sign in to continue to the study
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700">
                  Enter your username
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="username"
                    required
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={prolificId}
                    onChange={(e) => setProlificId(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Sign in
                </button>
              </div>
            </form>
            {errorMessage && (
              <div className="mt-4 text-sm text-red-500">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
